import React from "react"
import { Link } from "gatsby"
import {
  Navbar,
  Nav,
  Button,
  Container,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const CustomNavbar = ({ pageInfo }) => {
  const data = useStaticQuery(graphql`
    query NavBar {
      allContentfulSiteInfo {
        nodes {
          siteTitle
          siteLogo {
            fixed(width: 270) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
      allContentfulProductInfo {
        nodes {
          slug
          productName
          orderno
        }
      }
    }
  `)

  const brandComponent = (
    <>
      <Img
        fixed={data.allContentfulSiteInfo.nodes[0].siteLogo.fixed}
        alt="Sipariş Makinesi"
      />
    </>
  )

  const productLink = data.allContentfulProductInfo.nodes

  const menuList = productLink.map(product => {
    if (product.orderno < 1999) {
      return (
        <Dropdown.Item key={product.slug} className="nav-link">
          <Link
            to={"/solutions/" + product.slug}
            activeClassName="active"
            className="nav-link"
            sx={{
              fontSize: "18px",
              color: "black",
              boxShadow: "0 0 1px 1px rgba(0, 0, 0, .125)",
            }}
          >
            {product.productName}
          </Link>
        </Dropdown.Item>
      )
    }
  })

  const menuListDevices = productLink.map(product => {
    if (product.orderno > 1999) {
      return (
        <Dropdown.Item key={product.slug} className="nav-link">
          <Link
            to={"/solutions/" + product.slug}
            activeClassName="active"
            className="nav-link"
            sx={{
              fontSize: "18px",
              color: "black",
              boxShadow: "0 0 1px 1px rgba(0, 0, 0, .125)",
            }}
          >
            {product.productName}
          </Link>
        </Dropdown.Item>
      )
    }
  })

  //const productLinks = data.allContentfulProductInfo.nodes

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
        sticky="top"
      >
        <Container>
          <Navbar className="pl-0">
            <Link to="/" className="nav-link pl-0 pr-0">
              {brandComponent}
            </Link>
          </Navbar>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <DropdownButton
                variant="outline-success"
                title="Yazılım Çözümlerimiz"
                id="collasible-nav-dropdown"
              >
                {menuList}
              </DropdownButton>
              <DropdownButton
                title="Donanımsal Ürünlerimiz"
                id="collasible-nav-dropdown"
                variant="outline-secondary"
              >
                {menuListDevices}
              </DropdownButton>

              <Link to="/aboutus" activeClassName="active" className="nav-link">
                Hakkımızda{" "}
              </Link>

              <Link to="/contact" activeClassName="active" className="nav-link">
                İletişim
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
