import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { IconContext } from "react-icons"
import { Container, Row, Col } from "react-bootstrap"
import headerImage from "../images/header.png"
import Header from "./header"
import Navbar from "./navBar"
import Footer from "./Footer"

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <IconContext.Provider
          value={{ color: "green", className: "global-class-name" }}
        >
          <Container
            fluid
            className="px-0 main"
            style={{ backgroundImage: `url(${headerImage})` }}
          > 
            <Navbar pageInfo={pageInfo} sticky="top" />
            <Row noGutters>
              <Col>
                <Container className="mt-5">
                  <main>{children}</main>
                 
                </Container>
              </Col>
            </Row>
          </Container>
          <Container className="mt-5">
            <Footer />
          </Container>
        </IconContext.Provider>
      </>
    )}
  />
)

export default Layout
